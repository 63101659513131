import { TrackingProvider } from '@volvo-cars/tracking';
import type { ReactNode } from 'react';
import { FeaturedDocumentsList } from './FeaturedDocumentsList';
import {
  FeaturedDocumentsContext,
  FeaturedDocumentsProvider,
} from './FeaturedDocumentsProvider';
import { FeaturedDocumentsSkeleton } from './Skeleton/FeaturedDocumentsSkeleton';

export interface FeaturedDocumentsProps {
  featureListName: string;
  isTitleCentered?: boolean;
  limit?: number;
  trackingData?: { eventCategory: string };
  children?: ReactNode;
}

/**
 * @description This component is used to display a grid view of featured documents
 * @param featureListName - Name of the featured documents list
 * @param limit - Limit of documents to display - default is 6
 * @param trackingData - Data used for tracking
 * @param children - Children that will be loaded under the list. The url of the links inside that is pointing to the support/topic should be absolute
 * @param isTitleCentered - flag for setting the title to be centered
 * @returns A grid view of featured documents
 */
export const FeaturedDocuments = ({
  featureListName,
  limit = 6,
  trackingData,
  children,
  isTitleCentered,
}: FeaturedDocumentsProps) => {
  return (
    <TrackingProvider
      eventCategory={trackingData?.eventCategory || 'contextual article'}
    >
      <FeaturedDocumentsProvider
        featureListName={featureListName}
        limit={limit}
      >
        <FeaturedDocumentsContext.Consumer>
          {({ documents, isLoading, title, description }) => {
            if (isLoading) {
              return (
                <FeaturedDocumentsSkeleton
                  title={title}
                  description={description}
                  limit={limit}
                />
              );
            }
            if (!documents || documents.length === 0) {
              return null;
            }
            return (
              <FeaturedDocumentsList
                title={title}
                description={description}
                documents={documents}
                isTitleCentered={isTitleCentered}
              >
                {children}
              </FeaturedDocumentsList>
            );
          }}
        </FeaturedDocumentsContext.Consumer>
      </FeaturedDocumentsProvider>
    </TrackingProvider>
  );
};
