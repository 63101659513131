'use client';

import type { Evergage } from '@vcc-www/utils/personalization';
import type React from 'react';
import { useEffect, useState } from 'react';

const waitingTime = 3_000;

declare global {
  interface Window {
    Evergage?: Evergage;
  }
}

export default function RequiresEvergageAndEnabledPageTriggersToLoad({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        typeof window?.Evergage?.getCurrentPage === 'function' &&
        window.Evergage.getCurrentPage().user?.enablePageTriggers === true
      ) {
        setShowComponent(true);
      }
    }, waitingTime);

    return () => clearTimeout(timer);
  }, []);

  return showComponent ? children : null;
}
