import type { RequestDocument } from 'graphql-request';
import { request } from 'graphql-request';

const defaultApiUrl = 'https://www.volvocars.com/api/support/graphql';

/**
 * Support Content API helper method.
 * If the environment variable NEXT_PUBLIC_SUPPORT_CONTENT_GRAPHQL_URL is set, it will use that as the API URL.
 * If the window.origin includes volvocars.com it will use the prod version of the API.
 * The default apiUrl is the prod version of the API.
 *
 * @param document - GraphQL document to send to the server.
 * @param variables - Variables to send to the server.
 * @returns The response from the server.
 */
export const supportContentRequest = async <
  T extends unknown | { error: unknown } = unknown | { error: unknown },
>(
  document: RequestDocument,
  variables: Record<string, unknown>,
) => {
  let apiUrl = defaultApiUrl;

  if (process.env.NEXT_PUBLIC_SUPPORT_CONTENT_GRAPHQL_URL) {
    apiUrl = process.env.NEXT_PUBLIC_SUPPORT_CONTENT_GRAPHQL_URL;
  } else if (
    typeof window !== 'undefined' &&
    /^https:\/\/.+\.volvocars\.com(\.cn)?(:\d+)?$/.test(window.origin)
  ) {
    apiUrl = `${window.origin}/api/support/graphql`;
  }

  const requestHeaders = undefined;

  let result: T;

  try {
    result = await request<T>(apiUrl, document, variables, requestHeaders);
  } catch (error) {
    result = { error } as T;
    console.error(
      `Contextual-Support-Content: ${JSON.stringify(error, undefined, 2)}`,
    );
  }
  return result;
};
