'use client';
import { Disclaimer, DisclaimerRef } from '@vcc-package/text/disclaimers';
import { unescapeHTML } from '@vcc-www/utils/unescapeHtml';
import { cssMerge } from '@volvo-cars/css/utils';
import type React from 'react';
import { useId } from 'react';
import type MerchandisingPromotionProps from '../content-management/editorial-components/MerchandisingPromotion/merchandisingPromotion.props';
import MerchandisingClient from './merchandising-offers-overlay';
import MerchClient from './merchandising-overlay';
import styles from './merchandising.module.css';

type Props = {
  title: MerchandisingPromotionProps['content']['title'];
  label?: MerchandisingPromotionProps['content']['label'];
  body: MerchandisingPromotionProps['content']['body'];
  ctaText: MerchandisingPromotionProps['content']['ctaText'];
  overlay?: MerchandisingPromotionProps['content']['overlay'];
  offersOverlay?: MerchandisingPromotionProps['content']['offersOverlay'];
  annotatedDisclaimer?: MerchandisingPromotionProps['content']['annotatedDisclaimer'];
  className?: string;
};

const MerchandisingPromotionClient: React.FC<Props> = ({
  title,
  label,
  body,
  ctaText,
  overlay,
  offersOverlay,
  annotatedDisclaimer,
  className,
}) => {
  const generatedId = useId();

  return (
    <>
      <div
        className={cssMerge(
          styles['promo-container'],
          'grid md:grid-cols-2 gap-32 p-24 md:py-32 md:px-32 lg:px-64',
          className,
        )}
      >
        <div>
          {label && (
            <span className="micro bg-always-white rounded-full py-4 px-8">
              {label}
            </span>
          )}
          <h2 className="heading-1 mt-16">
            {title}
            {annotatedDisclaimer?.text && (
              <DisclaimerRef
                disclaimer={{
                  id: annotatedDisclaimer.contentType?.id || generatedId,
                  text: annotatedDisclaimer.text,
                  type: annotatedDisclaimer.type,
                }}
              />
            )}
          </h2>
        </div>
        <div>
          <p className="mb-32">{unescapeHTML(body)}</p>
          {offersOverlay && Object.keys(offersOverlay).length ? (
            <MerchandisingClient
              ctaText={ctaText}
              offersOverlayContent={offersOverlay}
            />
          ) : (
            overlay && (
              <MerchClient
                ctaText={ctaText}
                overlayContent={overlay}
                dialogTitle={label}
              />
            )
          )}
        </div>
      </div>
      {annotatedDisclaimer?.text && (
        <Disclaimer
          id={annotatedDisclaimer.contentType?.id || generatedId}
          includeSymbol
          text={annotatedDisclaimer.text}
          type={annotatedDisclaimer.type}
          className="mt-16 md:text-center md:mx-64"
        />
      )}
    </>
  );
};

export default MerchandisingPromotionClient;
