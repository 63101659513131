import { cssJoin, cssMerge } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import type React from 'react';
import styles from '../SubNavigation.module.css';
import { MobileCTAs } from './CTAs.client';
import type { CTAsProps } from './CTAs.types';

const CTAs: React.FC<CTAsProps> = (props) => {
  if (!props.primaryCta && !props.secondaryCta) {
    return null;
  }

  return (
    <>
      <DesktopCTAs {...props} />
      <MobileCTAs {...props} />
    </>
  );
};

const DesktopCTAs: React.FC<CTAsProps> = ({
  primaryCta,
  secondaryCta,
  className,
}) => (
  <div
    className={cssMerge(
      'flex-row-reverse gap-8 until-lg:hidden',
      styles.ctas,
      className,
    )}
    data-autoid="sub-navigation:desktop-ctas"
    data-testid="sub-navigation:desktop-ctas"
    slot="back"
  >
    {primaryCta?.text && primaryCta?.href && (
      <Track eventLabel={primaryCta.eventLabel}>
        <a
          href={primaryCta.href}
          target={primaryCta.target}
          className={cssJoin('button-filled', styles.cta)}
          data-size="small"
          data-sources={primaryCta.source}
        >
          {primaryCta.text}
        </a>
      </Track>
    )}
    {secondaryCta?.text && secondaryCta?.href && (
      <Track eventLabel={secondaryCta.eventLabel}>
        <a
          href={secondaryCta.href}
          target={secondaryCta.target}
          className="button-outlined"
          data-size="small"
          data-sources={secondaryCta.source}
        >
          {secondaryCta.text}
        </a>
      </Track>
    )}
  </div>
);

export default CTAs;
