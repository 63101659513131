import type {
  AnalyticsApiModel,
  LegalAddressApiModel,
  RequestApiModel,
} from '@vcc-package/leads-utils/api';
import {
  FlexFormFieldType,
  FlexFormLegalFieldType,
} from '@vcc-package/leads-utils/api';
import type { LeadsApp } from '@vcc-package/leads-utils/constants';
import { LeadsApplications } from '@vcc-package/leads-utils/constants';
import type {
  JSONObject,
  Query,
  UrlData,
} from '@vcc-package/leads-utils/types';
import { NonApiFieldName } from '@vcc-package/leads-utils/types';
import isEmpty from 'lodash/isEmpty';

import type { FormValues } from '../types/flexibleForm';

import type { PublicRuntimeConfig } from './config';

export interface CreateRequestInput {
  appId: LeadsApp;
  additionalInformation: AdditionalInformation;
  formValues: FormValues;
  languageId: string;
  marketId: string;
  query: Query;
  retailerInformation?: RetailerInformation;
  embeddableFormsAdditionalInfo?: JSONObject;
  consumerApp: string | null;
  isEmbeddableForms: boolean;
  publicRuntimeConfig: PublicRuntimeConfig;
}

interface AdditionalInformation {
  analytics?: AnalyticsApiModel;
  brand?: string;
  business?: boolean;
  campaignCode?: string;
  recaptchaToken: string;
  urlData?: UrlData;
}

interface RetailerInformation {
  customRetailerId?: string;
  retailerId?: string;
}

const booleanOrNull = (value: unknown) =>
  value !== undefined && value !== '' ? Boolean(value) : null;

const normalizeAdditionalInfo = (
  additionalInfo: CreateRequestInput['embeddableFormsAdditionalInfo'],
) => {
  if (!additionalInfo) return null;

  const additionalNotesFromLocalPage = additionalInfo.additionalNotes;

  if (additionalNotesFromLocalPage) {
    if (isEmpty(additionalNotesFromLocalPage)) return null;

    return typeof additionalNotesFromLocalPage === 'string'
      ? additionalNotesFromLocalPage
      : JSON.stringify(additionalNotesFromLocalPage, null, 4);
  }

  return JSON.stringify(additionalInfo, null, 4);
};

export const createRequestFromFlexForm = ({
  appId,
  additionalInformation,
  formValues,
  languageId,
  marketId,
  query,
  retailerInformation,
  consumerApp,
  embeddableFormsAdditionalInfo,
  isEmbeddableForms,
  publicRuntimeConfig,
}: CreateRequestInput): RequestApiModel => {
  const isSelectAllPresent =
    formValues[FlexFormLegalFieldType.SELECT_ALL_OPTIN] !== undefined;
  const purpose = LeadsApplications[appId].purpose;

  const additionalInfo = [
    query.additionalinfo,
    normalizeAdditionalInfo(embeddableFormsAdditionalInfo),
  ]
    .filter((x) => x)
    .join('\r\n');

  let skip: RequestApiModel['skip'] = null;

  //Ugly hack for skip must be an array
  if (query.skip) {
    if (!Array.isArray(query.skip)) {
      skip = [query.skip as string];
    } else {
      skip = query.skip;
    }
  }

  const getFormOptinValue = (fieldName: FlexFormLegalFieldType) =>
    isSelectAllPresent
      ? booleanOrNull(formValues[FlexFormLegalFieldType.SELECT_ALL_OPTIN])
      : booleanOrNull(formValues[fieldName]);

  const formValuesToRequestApiModel: RequestApiModel = {
    'g-recaptcha-response': additionalInformation.recaptchaToken,
    languageId,
    marketId,
    //Main form values
    title: formValues[FlexFormFieldType.TITLE],
    firstName: formValues[FlexFormFieldType.FIRSTNAME],
    surName: formValues[FlexFormFieldType.SURNAME],
    landline: formValues[FlexFormFieldType.LANDLINE],
    gender: formValues[FlexFormFieldType.GENDER],
    secondSurname: formValues[FlexFormFieldType.SECOND_SURNAME],
    mobilePhone: formValues[FlexFormFieldType.MOBILE_PHONE],
    email: formValues[FlexFormFieldType.EMAIL],
    postalCode: formValues[FlexFormFieldType.POSTAL_CODE],
    district: formValues[FlexFormFieldType.DISTRICT],
    city: formValues[FlexFormFieldType.CITY],
    addressOne: formValues[FlexFormFieldType.ADDRESS_ONE],
    addressTwo: formValues[FlexFormFieldType.ADDRESS_TWO],
    legalAddress: formValues[
      FlexFormFieldType.LEGAL_ADDRESS
    ] as unknown as LegalAddressApiModel,
    business:
      booleanOrNull(formValues[NonApiFieldName.Business]) ||
      additionalInformation.business ||
      false,
    legalName: formValues[NonApiFieldName.LegalName],
    phoneValidation: formValues[NonApiFieldName.PhoneValidation],
    description: formValues[NonApiFieldName.Description],
    smsOptIn: getFormOptinValue(FlexFormLegalFieldType.SMS_OPTIN),
    mailOptIn: getFormOptinValue(FlexFormLegalFieldType.MAIL_OPTIN),
    socialOptIn: getFormOptinValue(FlexFormLegalFieldType.SOCIAL_OPTIN),
    emailOptIn: getFormOptinValue(FlexFormLegalFieldType.EMAIL_OPTIN),
    telephoneOptIn: getFormOptinValue(FlexFormLegalFieldType.PHONE_OPTIN),
    cbvOptIn: getFormOptinValue(FlexFormLegalFieldType.CBV_OPTIN),
    //KİŞİSEL VERİLERİ KORUMA KURUMU (Turkish private data handling consent)
    kvkkOpt: formValues[NonApiFieldName.KvkkOptin],
    oneMarketingConsent: booleanOrNull(
      formValues[FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN],
    ),
    retailerId: retailerInformation?.retailerId ?? null,
    productsOfInterest: formValues.ProductsOfInterest
      ? (formValues.ProductsOfInterest as unknown as string[])
      : null,
    contactChannels: formValues[
      FlexFormFieldType.CONTACT_CHANNELS
    ] as unknown as string[],
    contactSlots: formValues[
      FlexFormFieldType.CONTACT_SLOTS
    ] as unknown as string[],
    campaignCode: additionalInformation?.campaignCode ?? '',
    emailId: query.emailid ?? '',
    brand: additionalInformation?.brand ?? null,
    vbsCarDataPath: query.vbscardatapath ?? null,
    analytics: additionalInformation?.analytics ?? null,
    skip,
    customretailerid: retailerInformation?.customRetailerId ?? null,
    addressCountry: query.source ?? null,
    cceId: query.cceid ?? null,
    cceUrl: query.cceurl ?? null,
    ccePricing: query.ccepricing ?? null,
    cceDisplayPrice: query.ccedisplayprice ?? null,
    additionalInfo,
    fullName: formValues[FlexFormFieldType.FULL_NAME],
    originalUrl: additionalInformation?.urlData?.originalUrl ?? '',
    referralUrl: additionalInformation?.urlData?.referralUrl ?? '',
    purpose,
    origin: consumerApp ?? 'leads-forms',
    originVersion: `${isEmbeddableForms ? 'embeddable-forms' : 'leads-forms'}=${publicRuntimeConfig.version}`,
    isTestLead: !!query.testing,
  };

  return formValuesToRequestApiModel;
};
