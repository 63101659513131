import type { KeyboardEventHandler, PropsWithChildren } from 'react';
import { useCallback } from 'react';

import { PurposeType, TranslationKey } from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import { getInsideSalesRetailer } from '@vcc-package/leads-utils/util';
import { cssMerge } from '@volvo-cars/css/utils';

import {
  Card,
  Header,
  HeadingWithIcon,
  useLeadsContext,
  useRetailer,
  useTranslate,
} from '@vcc-package/leads-utils';
import styles from './QuotePurposeSelector.module.css';

export const QuotePurposeSelector = ({
  children,
}: PropsWithChildren<unknown>) => {
  const translate = useTranslate();
  const {
    marketConfig,
    setPurpose,
    purposeConfig,
    purpose,
    formConfigs,
    inOverlay,
  } = useLeadsContext();
  const { onRetailerSelect } = useRetailer();

  const { insideSalesRetailer } = marketConfig;

  const hasSelection = !!purposeConfig && !!purpose;

  const insideSalesDealer =
    insideSalesRetailer &&
    getInsideSalesRetailer({
      translate,
      insideSalesRetailer,
    });

  const scrollToForm = useCallback(() => {
    const el = document.getElementById('embeddable-form-content');

    setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth' });
    }, 250);
  }, []);

  const onClick = useCallback(
    (purpose: PurposeType) => () => {
      if (purpose === PurposeType.CBV_CALLBACK && insideSalesDealer) {
        onRetailerSelect(insideSalesDealer);
      }
      setPurpose(purpose);

      setTimeout(scrollToForm, hasSelection ? 0 : 750);
    },
    [
      insideSalesDealer,
      onRetailerSelect,
      setPurpose,
      hasSelection,
      scrollToForm,
    ],
  );

  const onKeyDown = useCallback(
    (purpose: PurposeType) => {
      const innerFn: KeyboardEventHandler<HTMLDivElement> = (e) => {
        const enterOrSpace = e.key === 'Enter' || e.key === ' ';
        if (enterOrSpace) {
          onClick(purpose);
        }
      };

      return innerFn;
    },
    [onClick],
  );

  return (
    <div
      className={cssMerge(
        'flex flex-col items-center mx-auto md:px-0 w-full gap-48 lg:gap-64',
        !purpose && 'fade-in',
        inOverlay && 'gap-32 lg:gap-48',
      )}
    >
      {formConfigs?.[PurposeType.CBV_CALLBACK] && (
        <div
          data-testid="dealer_list_item_inside_sales"
          className={cssMerge(
            `flex flex-col ${globalStyling.sectionGap}`,
            inOverlay && 'gap-32 lg:gap-48',
          )}
        >
          <Header
            heading={translate(TranslationKey.REDES_RETAILER_MAP_HEADING)}
          />
          <div className={`flex flex-col ${globalStyling.fieldGap}`}>
            <Card
              dataTestid="embeddable_quote_inside_sales_btn"
              ariaSelected={purpose === PurposeType.CBV_CALLBACK}
              onClick={onClick(PurposeType.CBV_CALLBACK)}
              variant="large"
              onKeyDown={() => onKeyDown(PurposeType.CBV_CALLBACK)}
            >
              <HeadingWithIcon
                type="communication-phone-24"
                text={translate(TranslationKey.REDES_RETAILER_MAP_CALLBACK)}
              />
            </Card>
            <Card
              ariaSelected={purpose === PurposeType.OFFER_REQUEST}
              dataTestid="embeddable_quote_whole_sales_btn"
              onClick={onClick(PurposeType.OFFER_REQUEST)}
              variant="large"
              onKeyDown={() => onKeyDown(PurposeType.OFFER_REQUEST)}
            >
              <HeadingWithIcon
                type="office-retailer-40"
                text={translate(
                  TranslationKey.REDES_RETAILER_MAP_PHYSICAL_VISIT,
                )}
              />
            </Card>
          </div>
        </div>
      )}
      {hasSelection ? (
        <div
          id="embeddable-form-content"
          className={cssMerge(
            'w-full fade-in',
            inOverlay ? styles.scrollMarginTopOverlay : styles.scrollMarginTop,
          )}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
