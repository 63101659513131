'use client';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { PageTrackingProvider, TrackingProvider } from '@volvo-cars/tracking';

import { useLeadsContext } from '../context/leadsContext';
import {
  type LeadsTrackingContextValues,
  useLeadsTracking,
} from '../context/leadsTrackingContext';

// Need to split the Volvo trackers to its own to access useLeadsTracking()
export const VolvoTrackingHelper = ({
  trackPageViewOnLoad = true,
  children,
}: PropsWithChildren<{ trackPageViewOnLoad?: boolean }>) => {
  const { pageName, isEmbeddableForms } = useLeadsContext();

  const leadsTracking = useLeadsTracking();

  return (
    <TrackingProvider
      forceLowerCase={false}
      pageName={pageName}
      pageType="form"
    >
      {!isEmbeddableForms ? (
        <PageTrackingHelper
          leadsTracking={leadsTracking}
          trackPageViewOnLoad={trackPageViewOnLoad}
        >
          {children}
        </PageTrackingHelper>
      ) : (
        children
      )}
    </TrackingProvider>
  );
};

const PageTrackingHelper = ({
  children,
  leadsTracking,
  trackPageViewOnLoad,
}: PropsWithChildren<{
  leadsTracking?: LeadsTrackingContextValues;
  trackPageViewOnLoad?: boolean;
}>) => {
  const { purposeConfig } = useLeadsContext();

  if (!leadsTracking) {
    throw Error('Volvo Tracker Helper requires leadsTrackerHelper');
  }

  const pageViewData = leadsTracking.pageView?.() ?? {};
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      {trackPageViewOnLoad && mounted && purposeConfig?.events?.pageView ? (
        <PageTrackingProvider forceLowerCase={false} {...pageViewData}>
          {children}
        </PageTrackingProvider>
      ) : (
        children
      )}
    </>
  );
};
