'use client';
import { useEffect } from 'react';

import { getGtmFormTags, sendGALoadEvent } from '@vcc-package/leads-utils/util';
import { isStorybook } from '@vcc-www/constants';
import type { SalesChannel } from '@volvo-cars/tracking';
import { useTracker } from '@volvo-cars/tracking';

import { useLeadsContext } from '../context/leadsContext';
import { useLeadsTracking } from '../context/leadsTrackingContext';

export interface FormLoadEventTrackerProps {
  isB2b?: boolean;
  isVbs?: boolean;
  salesChannel?: SalesChannel;
}

/**
 * Component for sending form load tracking events to GTM
 */
export const FormLoadEventTracker = ({
  isB2b,
  isVbs,
  salesChannel,
}: FormLoadEventTrackerProps) => {
  const track = useTracker();
  const leadsTrackerHelper = useLeadsTracking();
  const {
    appId,
    siteSlug,
    isLoaded,
    isEmbeddableForms,
    overlayOpen,
    inOverlay,
  } = useLeadsContext();

  useEffect(() => {
    if (isStorybook) {
      console.info(
        'FormLoadEventTracker: Not sending form load event because we are in storybook',
      );
      return;
    }

    if (!isLoaded) {
      console.info(
        'FormLoadEventTracker: Not sending form load event because the form is not loaded',
      );
      return;
    }

    if (isEmbeddableForms && inOverlay && !overlayOpen) {
      console.info(
        'FormLoadEventTracker: Not sending form load event because overlay is not open',
        {
          overlayOpen,
          isEmbeddableForms,
        },
      );
      return;
    }

    const { formId, formType } = getGtmFormTags({
      appId,
      isB2b,
      isVbs,
      marketId: siteSlug,
    });

    // GA3
    sendGALoadEvent(formId, formType, salesChannel);

    // GA4
    const formLoadData = leadsTrackerHelper?.formLoad?.();
    if (formLoadData) {
      track.formLoad(formLoadData);
    }
  }, [
    appId,
    isB2b,
    isVbs,
    salesChannel,
    siteSlug,
    isLoaded,
    overlayOpen,
    inOverlay,
    track,
    leadsTrackerHelper,
    isEmbeddableForms,
  ]);

  return null;
};
