import type { PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { LeadsApp } from '../constants';

import type {
  RequestApiModel,
  RequestResponseApiModel,
} from '@vcc-package/leads-utils/api';
import { PurposeType, TranslationKey } from '@vcc-package/leads-utils/api';

import {
  type LeadsContextProperties,
  MasterThankYouPage,
  Spinner,
  useLeadsContext,
  useTranslate,
} from '@vcc-package/leads-utils';
import { useSetOverlayTitle } from '../hooks/useSetOverlayTitle';
import type { EmbeddableFormsWrapperProps } from './EmbeddableFormsWrapper';
import FormPage from './GenericFormComponent';
import { QuotePurposeSelector } from './QuotePurposeSelector';

type EmbeddableFormsPartialProps = PropsWithChildren<{
  value: LeadsContextProperties | undefined;
  parentProps: PropsWithChildren<EmbeddableFormsWrapperProps>;
}>;
const EmbeddableFormsPartial = ({
  value,
  parentProps,
  children,
}: EmbeddableFormsPartialProps) => {
  const { isLoaded, isEmbeddableForms, inOverlay } = useLeadsContext();
  const isQR = value?.appId === LeadsApp.QuoteRequest;
  const [submitData, setSubmitData] = useState<RequestApiModel | null>(null);
  const translate = useTranslate();

  const { features, purpose } = useLeadsContext();

  const title = translate(
    (purpose === PurposeType.KEEP_ME_UPDATED && features?.useDynamicTitle) ||
      (features?.showSubHeadingOnEF && purpose === PurposeType.OFFER_REQUEST)
      ? TranslationKey.OVERLAY_DIALOG_TITLE
      : value?.formConfig?.header.subHeading ||
          TranslationKey.REDES_RETAILER_MAP_SUB_HEADING,
  );

  useSetOverlayTitle(parentProps?.setOverlayTitle, title ?? '', inOverlay);

  const OnError =
    parentProps.OnError ??
    ((type, e) => {
      console.error(type, e);
    });

  const OnSubmitWrapped = useCallback(
    (submitData: RequestApiModel, apiResponse: RequestResponseApiModel) => {
      setSubmitData(submitData);

      if (parentProps.OnSubmit) {
        parentProps.OnSubmit(submitData, apiResponse);
      }
    },
    [parentProps],
  );

  if (!isLoaded) {
    const extraStyles = isEmbeddableForms
      ? undefined
      : {
          height: '100vh',
        };
    return <Spinner style={extraStyles} />;
  }

  if (submitData) {
    return <MasterThankYouPage />;
  }

  if (isQR) {
    return (
      <div className="flex flex-col mx-auto w-full">
        {parentProps.childrenPosition === 'top' ? children : <></>}
        <QuotePurposeSelector>
          <FormPage
            OnError={OnError}
            OnSubmit={OnSubmitWrapped}
            hideModelImage
            additionalInfo={parentProps.additionalInfo}
          />
        </QuotePurposeSelector>
        {parentProps.childrenPosition === 'bottom' ? children : <></>}
      </div>
    );
  }

  return (
    <div>
      <FormPage
        OnError={OnError}
        OnSubmit={OnSubmitWrapped}
        additionalInfo={parentProps.additionalInfo}
        hideModelImage
      />
    </div>
  );
};

export default EmbeddableFormsPartial;
