import styles from './featuredDocumentsSkeleton.module.css';

export interface FeaturedDocumentsSkeletonProps {
  limit: number;
  title?: string;
  description?: string;
}

/**
 * @description Renders a skeleton for the featured documents
 * @param limit - The number of skeleton articles to render
 * @param title - The title of the section
 * @param description - The description of the section
 * @returns
 */
export const FeaturedDocumentsSkeleton = ({
  limit,
  title,
  description,
}: FeaturedDocumentsSkeletonProps) => {
  const uniqueIds = Array.from({ length: limit }, (_, i) => `skeleton-${i}`);

  return (
    <section className="container">
      {title && <div className="h-32 mb-16" />}
      {description && <div className="h-24 mb-16" />}
      <div className="lg:grid-cols-3 md:grid-cols-2 grid gap-x-gutter gap-y-24">
        {[...Array(limit)].map((_, index) => (
          <article
            key={uniqueIds[index]}
            className="border rounded px-16 py-24 bg-primary"
          >
            <div
              className={`h-24 mb-8 bg-secondary rounded ${styles.featuredDocumentsSkeleton}`}
            />
            <div
              className={`mb-24 bg-secondary rounded ${styles.featuredDocumentsSkeleton} ${styles.skeletonItemDescription}`}
            />
            <div
              className={`h-16 bg-secondary rounded w-64 ${styles.featuredDocumentsSkeleton}`}
            />
          </article>
        ))}
      </div>
    </section>
  );
};
