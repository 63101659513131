'use client';

import { useOnClickOutside } from '@vcc-www/hooks';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { cssJoin, cssMerge } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';
import type React from 'react';
import { useRef, useState } from 'react';
import styles from '../SubNavigation.module.css';
import { useSiteFooterMobileStyling } from '../utils/useSiteFooterMobileStyling';
import type { CTAsProps } from './CTAs.types';

interface CSSPropertiesWithVariable extends React.CSSProperties {
  '--amount-of-ctas': number;
}

export const MobileCTAs: React.FC<CTAsProps> = ({
  primaryCta,
  secondaryCta,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const sharedTranslate = useSharedComponentsTranslate();
  useSiteFooterMobileStyling();

  useOnClickOutside(containerRef, () => {
    setIsExpanded(false);
  });
  const amountOfCtas = [primaryCta, secondaryCta].filter(Boolean).length;

  return (
    <div
      ref={containerRef}
      className={cssMerge(
        'lg:hidden w-full flex gap-8 justify-end px-pagemargin md:px-0 items-end fixed',
        styles.mobileCtaContainer,
        className,
      )}
      data-autoid="sub-navigation:mobile-ctas"
      data-testid="sub-navigation:mobile-ctas"
    >
      <div
        id="sub-navigation:ctas-container"
        className={cssJoin(
          'w-full overflow-hidden gap-8',
          styles.mobileCtaInnerContainer,
        )}
        style={
          {
            '--amount-of-ctas': amountOfCtas,
          } as CSSPropertiesWithVariable
        }
        data-expanded={isExpanded}
      >
        {primaryCta?.text && primaryCta?.href && (
          <Track eventLabel={primaryCta.eventLabel}>
            <a
              href={primaryCta.href}
              target={primaryCta.target}
              className={cssJoin('button-filled w-full', styles.cta)}
              data-sources={primaryCta.source}
            >
              {primaryCta.text}
            </a>
          </Track>
        )}
        {secondaryCta?.text && secondaryCta?.href && (
          <Track eventLabel={secondaryCta.eventLabel}>
            <a
              href={secondaryCta.href}
              target={secondaryCta.target}
              className="button-filled w-full mt-8"
            >
              {secondaryCta.text}
            </a>
          </Track>
        )}
      </div>
      {amountOfCtas > 1 && (
        <Track eventLabel={isExpanded ? 'collapse' : 'expand'}>
          <button
            type="button"
            className={cssJoin(
              'button-filled px-0 w-full',
              styles.cta,
              styles.expandButton,
            )}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label={
              isExpanded
                ? sharedTranslate(
                    'SubNavigation.subNavigation.expandButtonAriaLabelCollapse',
                  )
                : sharedTranslate(
                    'SubNavigation.subNavigation.expandButtonAriaLabelExpand',
                  )
            }
            aria-controls="sub-navigation:ctas-container"
          >
            <Icon
              icon="chevron-up"
              size={16}
              className="button-expanded:rotate-180 transition-transform"
              color="currentColor"
            />
          </button>
        </Track>
      )}
    </div>
  );
};
