/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FlexFormFieldType {
  TITLE = 'Title',
  GENDER = 'Gender',
  FIRSTNAME = 'Firstname',
  SURNAME = 'Surname',
  SECOND_SURNAME = 'SecondSurname',
  MOBILE_PHONE = 'MobilePhone',
  MOBILE_PHONE_ASYNC = 'MobilePhoneAsync',
  LANDLINE = 'Landline',
  EMAIL = 'Email',
  EMAIL_ASYNC = 'EmailAsync',
  LEGAL_ADDRESS = 'LegalAddress',
  LEGAL_ADDRESS_ASYNC = 'LegalAddressAsync',
  PLAIN_ADDRESS = 'PlainAddress',
  CITY = 'City',
  POSTAL_CODE = 'PostalCode',
  DISTRICT = 'District',
  ADDRESS_ONE = 'AddressOne',
  ADDRESS_TWO = 'AddressTwo',
  NOTES = 'Notes',
  B2B = 'B2b',
  ACCOMPANIED = 'Accompanied',
  SPACE = 'Space',
  ADDRESS_ASYNC_WITH_MANUAL = 'AddressAsyncWithManual',
  PRODUCTS_OF_INTEREST = 'ProductsOfInterest',
  CONTACT_CHANNELS = 'ContactChannels',
  CONTACT_SLOTS = 'ContactSlots',
  FULL_NAME = 'FullName',
  TITLE_YOUR_DETAILS = 'TitleYourDetails',
  TITLE_CAR_DETAILS = 'TitleCarDetails',
  LICENSE_PLATE = 'LicensePlate',
  MILEAGE = 'Mileage',
  CONDITION_OF_CAR = 'ConditionOfCar',
  MODEL_YEAR = 'ModelYear',
  CONTACT_SLOTS_SINGLE = 'ContactSlotsSingle',
  PHONE_WITH_COUNTRY_CODE = 'PhoneWithCountryCode',
}
