'use client';

import { LeadsApp } from '@vcc-package/leads-utils/constants';
import { useInView } from '@vcc-www/hooks';
import type React from 'react';
import { type PropsWithChildren, useCallback, useState } from 'react';
import { DialogTrigger } from './DialogTrigger';
import { EmbeddableForms } from './EmbeddableForms';
import type { EmbeddableFormsWrapperProps } from './EmbeddableFormsWrapper';

export const EmbeddableFormsOverlay = (
  props: PropsWithChildren<
    EmbeddableFormsWrapperProps & {
      hashTrigger?: string;
      onClick?: (e?: React.MouseEvent<Element>) => void;
      dialogTriggerProps?: React.ButtonHTMLAttributes<HTMLButtonElement> & {
        [key: `data-${string}`]: unknown;
      };
      dialogTriggerClassName?: string;
    }
  >,
) => {
  const [contentInViewRef, contentInView] = useInView<HTMLDivElement>({
    triggerOnce: true,
  });
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayTitle, setOverlayTitle] = useState('');
  const expandedProps = { ...props, setOverlayTitle, overlayOpen };

  const onOpen = useCallback(() => {
    setOverlayOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOverlayOpen(false);
  }, []);

  return !contentInView ? (
    <div ref={contentInViewRef} />
  ) : (
    <DialogTrigger
      title={overlayTitle}
      dialogContent={<EmbeddableForms {...expandedProps} />}
      onExitIntentIntroductionComponent={
        props.onExitIntentIntroductionComponent
      }
      appId={LeadsApp[props.formType]}
      hashTrigger={props.hashTrigger}
      consumerApp={props.consumerApp}
      onClick={props.onClick}
      dialogTriggerClassName={props.dialogTriggerClassName}
      dialogTriggerProps={props.dialogTriggerProps}
      onOpen={onOpen}
      onClose={onClose}
    >
      {props.children}
    </DialogTrigger>
  );
};
