'use client';
import type { MouseEvent, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAbTesting } from '@vcc-package/leads-utils/abTesting';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  type FieldErrors,
  FormProvider,
  type SubmitErrorHandler,
  useForm,
} from 'react-hook-form';

import type { FormValues } from '../../types/flexibleForm';
import { useLeadsContext } from '../context/leadsContext';

import type { AdditionalProps } from './flexibleForm';

import { FlexibleForm } from '.';

export type LoadingDebouncedType = (value: boolean, event?: MouseEvent) => void;

export type LeadsFormSubmitHandler = (
  data: FormValues,
  recaptchaToken: string,
  setLoadingDebounced: LoadingDebouncedType,
  loading: boolean,
) => Promise<undefined | boolean>;
export type LeadsFormErrorHandler = (
  errors: Error,
  setLoadingDebounced: LoadingDebouncedType,
) => void;

export type LeadsFormProps = {
  additionalProps?: AdditionalProps;
  onError: LeadsFormErrorHandler;
  onSubmit: LeadsFormSubmitHandler;
};

export const LeadsForm = ({
  additionalProps,
  onError,
  onSubmit,
  children,
}: PropsWithChildren<LeadsFormProps>) => {
  ///
  // Hooks
  //////////
  const context = useLeadsContext();
  const {
    marketConfig,
    features,
    navigation: { query },
    prefilledData,
  } = context;
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const recaptchaKey = marketConfig.recaptchaKey;
  const useRecaptcha = query.testing !== 'true' && !features.disableRecaptcha;

  const defaultValues = useMemo(() => {
    const defaultValues = additionalProps?.defaultValues
      ? { ...additionalProps.defaultValues, ...prefilledData }
      : prefilledData;

    if (defaultValues) {
      for (const [key, value] of Object.entries(defaultValues)) {
        if (value && Array.isArray(value)) {
          (defaultValues as Record<string, string | undefined>)[key] =
            value.join(',');
        }
      }
    }

    return defaultValues as Record<string, string | undefined>;
  }, [additionalProps?.defaultValues, prefilledData]);

  const formContext = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues ?? undefined,
  });

  useEffect(() => {
    if (prefilledData) {
      formContext.reset(defaultValues);
    }
  }, [prefilledData, formContext, defaultValues]);

  useAbTesting(context, query.disableexperiments === 'true');

  const { handleSubmit } = formContext;

  const setLoadingDebounced: LoadingDebouncedType = useCallback(
    (value: boolean, event?: MouseEvent) => {
      if (event) {
        event.persist();
      }
      if (timer) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setLoading(value);
        event?.preventDefault();
      }, 0);
    },
    [],
  );

  const catchError: SubmitErrorHandler<FormValues> = useCallback(
    (errors) => {
      const outerError = new Error('AggregateError', { cause: errors });
      onError(outerError, setLoadingDebounced);
    },
    [onError, setLoadingDebounced],
  );

  const catchSubmit = useCallback(
    async (data: FormValues) => {
      try {
        let recaptchaToken = '';
        if (useRecaptcha && recaptchaRef) {
          recaptchaRef.current?.reset();
          recaptchaToken = (await recaptchaRef.current?.executeAsync()) ?? '';
        }

        await onSubmit(data, recaptchaToken, setLoadingDebounced, loading);
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          catchError(e as FieldErrors<FormValues>);
        } else if (typeof e === 'string') {
          catchError(new Error(e) as FieldErrors<FormValues>);
        } else {
          //Unknown error type here. A non Error object or string was thrown.
          //We try to convert it to a string and catch it in the error callback
          catchError(new Error(String(e)) as FieldErrors<FormValues>);
        }
      }
    },
    [catchError, loading, onSubmit, setLoadingDebounced, useRecaptcha],
  );

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={handleSubmit(catchSubmit, catchError)}
        noValidate
        className={`flex flex-col ${globalStyling.sectionGap}`}
      >
        <FlexibleForm
          additionalProps={additionalProps}
          loading={loading}
          setLoadingDebounced={setLoadingDebounced}
        />
        {children}
        {useRecaptcha && recaptchaKey && (
          <ReCAPTCHA
            className="mb-8"
            sitekey={recaptchaKey}
            size="invisible"
            ref={recaptchaRef}
            badge="inline"
          />
        )}
      </form>
    </FormProvider>
  );
};
